import { createAction, props } from '@ngrx/store';
import {  EstateObject } from '../models/estate-object.model';

export const getHmacEstates = createAction('[Estates] Get Hmac Estates');

export const getHmacEstatesSuccess = createAction('[Estates] Get Hmac Estates Success', props<{ hmac: string }>());

export const getHmacEstatesFailure = createAction('[Estates] Get Hmac Estates Failure', props<{ error: string }>());

export const getHmacEstatesImages = createAction(
  '[Estates] Get Hmac  Images Estates',
  props<{ estateIds: string[]; estates: EstateObject[] }>()
);

export const getHmacEstatesImagesSuccess = createAction(
  '[Estates] Get Hmac Images Estates Success',
  props<{ hmac: string; estateIds: string[]; estates: EstateObject[] }>()
);

export const getHmacEstatesImagesFailure = createAction(
  '[Estates] Get Hmac Estates Failure',
  props<{ error: string }>()
);

export const getEstates = createAction('[Estates] Get Estates');

export const getEstatesSuccess = createAction(
  '[Estates] Get Estates Success',
  props<{ estates: EstateObject[]; estateIds: string[] }>()
);

export const getEstatesFailure = createAction('[Estates] Get Estates Failure', props<{ error: string }>());

export const getEstatesImages = createAction(
  '[Estates] Get Estates',
  props<{ estateIds: string[]; estates: EstateObject[] }>()
);

export const getEstatesImagesSuccess = createAction(
  '[Estates] Get Estates Images Success',
  props<{ estates: EstateObject[] }>()
);

export const getEstatesImagesFailure = createAction('[Estates] Get Estates Images Failure', props<{ error: string }>());

export const setFilter = createAction('[Estates] Set Filter for Estates', props<{ filter: any }>());

export const setFilterSuccess = createAction('[Estates] Set Filter for Estates Success', props<{ filter: string }>());

export const setFilterSuccessFailure = createAction(
  '[Estates] Set Filter for Estates Failure',
  props<{ error: string }>()
);

export const setResourceId = createAction('[Estates] Set ResourceId for Estates', props<{ resourceid: number }>());

export const setResourceIdSuccess = createAction(
  '[Estates] Set ResourceId for Estates Success',
  props<{ resourceid: number }>()
);

export const setResourceIdFailure = createAction(
  '[Estates] Set ResourceId for Estates Failure',
  props<{ error: string }>()
);
