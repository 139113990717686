import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EstatesState } from './estates.state';
import { selectEstateIdFromRoute } from './router.selectors';

export const getEstatesState = createFeatureSelector<EstatesState>('estates');

export const selectAllEstates = createSelector(getEstatesState, (estatetState: EstatesState) => estatetState?.estates);

export const selectEstateById = createSelector(
  getEstatesState,
  selectEstateIdFromRoute,
  (estatetState: EstatesState, estateId: string) => estatetState?.estates?.find((estate) => estate.Id === estateId)
);

export const selectFilter = createSelector(getEstatesState, (estatetState: EstatesState) => estatetState?.filter);
export const selectResourceId = createSelector(
  getEstatesState,
  (estatetState: EstatesState) => estatetState?.resourceid
);
